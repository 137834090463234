<template>
  <div class="equipmentInfo">
    <h1>
      {{ $t('equipmentManage.equipmentInfo') }}
      <el-button
        v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_VIEW')"
        class="seeView"
        type="text"
        @click="$emit('view', 'view')"
        >{{ $t('base.view') }}</el-button
      >
    </h1>
    <ul>
      <li>
        <div class="list">
          <!-- lang:设备名称 -->
          <div>{{ $t('equipmentManage.equipmentName') }}</div>
          <div>{{ tableRow.name }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:所属分组 -->
          <div>{{ $t('equipmentManage.equipmentGroup') }}</div>
          <div>{{ tableRow.equipmentGroup.name }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:所属部门 -->
          <div>{{ $t('equipmentManage.equipmentUserAccountGroup') }}</div>
          <div>{{ $util.getTreeNames(tableRow.userAccountGroup, 'name') }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:出厂编号 -->
          <div>{{ $t('equipmentManage.equipmentNumber') }}</div>
          <div>{{ tableRow.number }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:设备型号 -->
          <div>{{ $t('equipmentManage.equipmentModel') }}</div>
          <div>{{ tableRow.model }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:设备规格 -->
          <div>{{ $t('equipmentManage.equipmentSpecifications') }}</div>
          <div>{{ tableRow.specifications }}</div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:设备图片 -->
          <div>{{ $t('equipmentManage.equipmentImageSet') }}</div>
          <div style="background: transparent; padding: 0">
            <el-image
              v-for="item in tableRow.imageSet"
              :key="item.id"
              :src="item.fileHost + '/' + item.objectName"
              @click="openPreview(item.fileHost + '/' + item.objectName)"
            />
          </div>
        </div>
      </li>
      <li>
        <div class="list">
          <!-- lang:相关附件 -->
          <div>{{ $t('equipmentManage.equipmentFileSet') }}</div>
          <div style="background: transparent; padding: 0">
            <p v-for="item in tableRow.fileSet" :key="item.id">
              <el-link
                :href="item.fileHost + '/' + item.objectName"
                target="_blank"
                type="primary"
                >{{ item.originalName }}</el-link
              >
            </p>
          </div>
        </div>
      </li>
    </ul>
    <preview-image v-if="showPreview" :src="previewUrl" @close="closePreview" />
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      showPreview: false,
      previewUrl: '',
    }
  },
  methods: {
    openPreview(url) {
      this.previewUrl = url
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
  },
}
</script>